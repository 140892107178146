import React from 'react';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';

import { useToggleMessageArchived } from '../../../../../../../hooks/useToggleMessageArchived';

import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';

import {
  TOGGLE_MESSAGE_ARCHIVED_QUERY,
  ToggleMessageArchivedRecordType
} from '../../../../../../../queries/toggleMessageArchived.query';

import { messagesKeys } from '../../../../../../../../../locales/keys';

interface MessageDropdownArchivedButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
  messagesCacheKey: FetchMessagesCacheKey;
}

function MessageDropdownArchivedButton({
  message,
  messagesCacheKey
}: MessageDropdownArchivedButtonProps) {
  const { toggleMessageArchived, toggleMessageArchivedLoading } =
    useToggleMessageArchived<ToggleMessageArchivedRecordType>({
      indexCacheKey: messagesCacheKey,
      query: TOGGLE_MESSAGE_ARCHIVED_QUERY
    });

  const handleToggleMessageArchived = () =>
    toggleMessageArchived({ uuid: message.uuid });

  return (
    <PureButtonHelper
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      disabled={toggleMessageArchivedLoading}
      i18nText={
        message.archivedAt ? messagesKeys.restore : messagesKeys.archive
      }
      onClick={handleToggleMessageArchived}
    />
  );
}

export default MessageDropdownArchivedButton;
