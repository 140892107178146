import compact from 'lodash/compact';

import { MessageUUID } from '../../messagesTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface ToggleMessageArchivedOptions {
  indexCacheKey?: string;
  query: string;
}

interface ToggleMessageArchivedInput {
  uuid: MessageUUID;
}

interface ToggleMessageArchivedError {
  fullMessages: string[] | null;
}

interface ToggleMessageArchivedResponse<ToggleMessageArchivedRecordType> {
  toggleMessageArchived: {
    status: string;
    record: ToggleMessageArchivedRecordType;
    errors: ToggleMessageArchivedError;
  };
}

const action = 'toggleMessageArchived';

function useToggleMessageArchived<ToggleMessageArchivedRecordType>({
  indexCacheKey,
  query
}: ToggleMessageArchivedOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    ToggleMessageArchivedInput,
    ToggleMessageArchivedResponse<ToggleMessageArchivedRecordType>,
    ToggleMessageArchivedError,
    ToggleMessageArchivedRecordType
  >({ action, cacheKeys: compact([indexCacheKey]), query });

  return {
    toggleMessageArchivedData: updateQueryData,
    toggleMessageArchivedError: updateQueryError,
    toggleMessageArchivedLoading: updateQueryLoading,
    toggleMessageArchivedErrorMessage: updateQueryErrorMessage,
    toggleMessageArchived: updateQuery,
    toggleMessageArchivedReset: updateQueryReset
  };
}

export default useToggleMessageArchived;
