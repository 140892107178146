import React, { useMemo } from 'react';
import map from 'lodash/map';

import { MessageDropdownAddToRecordsButtonMessage } from './MessageDropdownAddToRecordsButton.types';
import { FetchUsersFilters } from '../../../../../../../../users/usersTypes';

import {
  FETCH_TEAMS_USERS_QUERY,
  FetchTeamsUsersQueryResponse
} from '../../../../../../../../teamsUsers/queries/fetchTeamsUsers.query';

import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';
import { useTeamsUsers } from '../../../../../../../../teamsUsers/hooks/useTeamsUsers';

import { CreateOperationModalButton } from '../../../../../../../../operations/components/modalButtons/CreateOperationModalButton';

import { messagesKeys } from '../../../../../../../../../locales/keys';

import { dateFnsConvert } from '../../../../../../../../../utils/dateFnsConvert';
import { convertFileToDropzoneFile } from '../../../../../../../../../helpers/dropzone/DropzoneHelper/utils/convertFileToDropzoneFile';

import { TeamsUserCache } from '../../../../../../../../teamsUsers/TeamsUserCache';
import { UsersPermissions } from '../../../../../../../../users/usersConstants';

interface MessageDropdownAddToRecordsButtonProps {
  message: MessageDropdownAddToRecordsButtonMessage;
}

function MessageDropdownAddToRecordsButton({
  message
}: MessageDropdownAddToRecordsButtonProps) {
  const currentUser = useCurrentUser();

  const { teamsUsers, teamsUsersFetched, teamsUsersIsPlaceholderData } =
    useTeamsUsers<FetchTeamsUsersQueryResponse>({
      cacheKey: TeamsUserCache.teamTeamsUsersCacheKey(
        message.task?.project?.team?.nanoId || message.project?.team?.nanoId
      ),
      query: FETCH_TEAMS_USERS_QUERY,
      initialLimit: 1000,
      initialFilters: {
        teamNanoId: {
          eq:
            message.task?.project?.team?.nanoId || message.project?.team?.nanoId
        }
      }
    });

  const initialFileIds = useMemo(
    () => map(message?.fileAttachments, 'id'),
    [message?.fileAttachments]
  );

  const initialFiles = useMemo(
    () => convertFileToDropzoneFile(message?.fileAttachments),
    [message?.fileAttachments]
  );

  const userSelectInitialFilters = useMemo<FetchUsersFilters>(
    () => ({ id: { in: map(teamsUsers, (teamUser) => teamUser.user.id) } }),
    [teamsUsers]
  );

  const clientId =
    message.task?.project?.owner?.id || message.project?.owner?.id;

  return (
    <CreateOperationModalButton
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      i18nText={messagesKeys.addToRecords}
      clientId={clientId}
      body={message.body}
      completeDatetime={dateFnsConvert.toDateTimeLocal(message.createdAt)}
      initialFileIds={initialFileIds}
      initialFiles={initialFiles}
      withToastOnSuccess
      projectId={message.projectId}
      taskId={message.taskId}
      teamNanoId={
        message.task?.project?.team?.nanoId || message.project?.team?.nanoId
      }
      disabled={!teamsUsersFetched || teamsUsersIsPlaceholderData}
      withUserSelect
      withMentionedUserIds={currentUser.hasPermissions(
        currentUser.id === clientId
          ? UsersPermissions.CHANGE_SELF_ADD_RECORD_MENTIONED_USER_IDS
          : UsersPermissions.CHANGE_USER_ADD_RECORD_MENTIONED_USER_IDS
      )}
      userSelectInitialFilters={userSelectInitialFilters}
    />
  );
}

export default MessageDropdownAddToRecordsButton;
