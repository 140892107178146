import { useCallback } from 'react';

import { ClassName, I18nText, IsDisabled } from '../../../../../types';
import {
  CREATE_MESSAGE_IN_TASK_QUERY,
  CreateMessageInTaskQueryResponse
} from '../../../queries/createMessageInTask.query';
import {
  CREATE_MESSAGE_IN_PROJECT_QUERY,
  CreateMessageInProjectQueryResponse
} from '../../../queries/createMessageInProject.query';
import { IconsEnum } from '../../../../../assets/icons/types';

import { useCreateMessageInProject } from '../../../hooks/useCreateMessageInProject';
import { useCreateMessageInTask } from '../../../hooks/useCreateMessageInTask';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useToastNotification } from '../../../../../common/hooks/useToastNotification';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../list/ItemMessagesListMessage/components/ItemMessagesListMessageMenuDropdown';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { messagesKeys } from '../../../../../locales/keys';

interface PublishMessageButtonProps {
  disabled?: IsDisabled;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  isDropdown?: boolean;
  message: ItemMessagesListMessageMenuDropdownMessage;
  tooltipI18nText?: I18nText;
}

function PublishMessageButton({
  disabled,
  i18nText,
  icon,
  iconClassName,
  isDropdown,
  message,
  tooltipI18nText
}: PublishMessageButtonProps) {
  const { showToastI18nNotification } = useToastNotification({
    appearance: 'success',
    i18nMessage: messagesKeys.sent
  });

  const {
    createMessageInTaskLoading,
    createMessageInTaskErrorMessage,
    createMessageInTask
  } = useCreateMessageInTask<CreateMessageInTaskQueryResponse>({
    taskNanoId: message.task?.nanoId,
    query: CREATE_MESSAGE_IN_TASK_QUERY
  });

  const {
    createMessageInProjectLoading,
    createMessageInProjectErrorMessage,
    createMessageInProject
  } = useCreateMessageInProject<CreateMessageInProjectQueryResponse>({
    projectNanoId: message.project?.nanoId,
    query: CREATE_MESSAGE_IN_PROJECT_QUERY
  });

  const handlePublishMessage = useCallback<() => void>(async () => {
    if (message.taskId) {
      await createMessageInTask({
        body: message.body,
        taskId: message.taskId,
        visibleForClient: false
      });

      return showToastI18nNotification();
    }
    if (message.projectId) {
      await createMessageInProject({
        body: message.body,
        projectId: message.projectId,
        visibleForClient: false
      });

      return showToastI18nNotification();
    }
  }, [
    createMessageInTask,
    createMessageInProject,
    message,
    showToastI18nNotification
  ]);

  useShowToastOnErrorChange({
    error: createMessageInTaskErrorMessage || createMessageInProjectErrorMessage
  });

  return (
    <PureTooltipIconButtonHelper
      className={
        isDropdown
          ? 'text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap'
          : 'py-0.5 pl-0.5 pr-0.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0'
      }
      disabled={
        disabled || createMessageInTaskLoading || createMessageInProjectLoading
      }
      i18nText={i18nText}
      onClick={handlePublishMessage}
      icon={icon}
      iconClassName={iconClassName || 'h-5 w-5'}
      tooltipI18nText={tooltipI18nText}
    />
  );
}

export default PublishMessageButton;
