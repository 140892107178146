import { gql } from 'graphql-request';

import { MessageArchivedAt } from '../messagesTypes';

export interface ToggleMessageArchivedRecordType {
  archivedAt: MessageArchivedAt;
}

export const TOGGLE_MESSAGE_ARCHIVED_QUERY = gql`
  mutation ToggleMessageArchived($uuid: ID!) {
    toggleMessageArchived(input: { uuid: $uuid }) {
      errors {
        fullMessages
      }
      recordUuid
      status
      record {
        archivedAt
      }
    }
  }
`;
