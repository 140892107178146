import React, { useMemo } from 'react';
import TurndownService from 'turndown';
import isEmpty from 'lodash/isEmpty';
import replace from 'lodash/replace';

import { ItemMessagesListMessageMenuDropdownMessage } from '../../ItemMessagesListMessageMenuDropdown.types';

import { CreateMessageWithTodoItemsButton } from '../../../../../../../../todoItems/components/modalButtons/CreateMessageWithTodoItemsButton';

import { todoItemsKeys } from '../../../../../../../../../locales/keys';

const turndownService = new TurndownService().addRule('strikethrough', {
  filter: ['del', 's'], // 'strike'
  replacement: (content) => `~~${content}~~`
});

function isHTML(text: string) {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i;
  return htmlRegex.test(text);
}

interface MessageDropdownCreateTodoListButtonProps {
  message: ItemMessagesListMessageMenuDropdownMessage;
}

function MessageDropdownCreateTodoListButton({
  message
}: MessageDropdownCreateTodoListButtonProps) {
  const body = useMemo<string>(() => {
    const msg = message.body || message.forwardedMessage?.body;

    if (!msg) {
      return '';
    }

    if (isHTML(msg)) {
      return replace(
        turndownService.turndown(msg),
        /(?=<!--)([\s\S]*?)-->/g,
        ''
      );
    }

    return msg;
  }, [message.body, message.forwardedMessage?.body]);

  if (!isEmpty(message.todoItems) || !body) {
    return null;
  }

  return message.project?.nanoId ? (
    <CreateMessageWithTodoItemsButton
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      i18nText={todoItemsKeys.createTodoList}
      visibleForClient={message.visibleForClient}
      defaultValues={body.split(/(?:\r?\n)+/)}
      projectId={message.projectId}
      projectNanoId={message.project.nanoId}
    />
  ) : (
    <CreateMessageWithTodoItemsButton
      className="text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-3 3xl:px-4 py-1.5 3xl:py-2 w-full flex items-center text-xs 3xl:text-sm whitespace-nowrap"
      i18nText={todoItemsKeys.createTodoList}
      visibleForClient={message.visibleForClient}
      defaultValues={body.split(/(?:\r?\n)+/)}
      taskId={message.taskId}
      taskNanoId={message.task?.nanoId}
    />
  );
}

export default MessageDropdownCreateTodoListButton;
